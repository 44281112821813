/* Global Constants */
export const APP_Prefix = "erekrut29_";
export const APP_NAME = "EREKRUT";
/* Gloabl Constants */

/* Api Response Constants */
export const GET_TOP_COMPANY = "GET_TOP_COMPANY";
export const GET_TOP_CAMPUS = "GET_TOP_CAMPUS";
export const GET_TOP_JOBS = "GET_TOP_JOBS";
export const GET_PLANS = "GET_PLANS";
export const SELECTPLAN = "SELECT-PLAN";
export const SelectFeatures = "SELECT-FEATURES";
export const SAVE_BILLING_DETAILS = "SAVE-BILLING-DETAILS";
export const GET_JOBS = "GET_JOBS";
export const GET_STATS = "stats_counter";
export const OTP_Validate_Resp = "OTP_Validate_Resp";
export const Signup_Submit_Resp = "Signup_Submit_Resp";
export const Candidate_User_Type_ID = 1;
export const Recruiter_User_Type_ID = 2;
export const Campus_User_Type_ID = 3;
export const Login_User = "Login_User";
export const Login_Resp = "Login_Resp";
export const Forgot_Password_Resp = "Forgot_Password_Resp";
export const Update_Password_Resp = "Update_Password_Resp";
export const Update_Link_Password_Resp = "Update_Link_Password_Resp";

export const OTP_Resp = "OTP_Resp";
export const Register_Resp = "Register_Resp";
export const Add_Job_Resp = "Add_Job_Resp";
export const Add_Question_Resp = "Add_Question_Resp";
export const GET_JOB_FORM_DATA = "GET_JOB_FORM_DATA";
export const GET_USER_PROFILE_RESP = "GET_USER_PROFILE_RESP";
export const GET_SEARCH_RESP = "GET_SEARCH_RESP";
export const GET_CREATE_QUIZ_RESP = "GET_CREATE_QUIZ_RESP";
export const GET_UPDATE_QUIZ_RESP = "GET_UPDATE_QUIZ_RESP";
export const Update_Profile_Resp = "Update_Profile_Resp";
export const OTP_RESEND_RESP = "OTP_RESEND_RESP";
export const SOCIAL_LOGIN_RESP = "SOCIAL_LOGIN_RESP";
export const GET_SKILLS_RESP = "GET_SKILLS_RESP";
export const GET_QUIZ_RESP = "GET_QUIZ_RESP";
export const GET_QUIZ_STATUS_RESP = "GET_QUIZ_STATUS_RESP";
export const SKIP_QUESTIONS_RESP = "SKIP_QUESTIONS_RESP";
export const GET_LETTER_RESP = "GET_LETTER_RESP";
export const CREATE_LETTER_RESP = "CREATE_LETTER_RESP";
export const GET_TEMPLATE_RESP = "GET_TEMPLATE_RESP";
export const CREATE_TEMPLATE_RESP = "CREATE_TEMPLATE_RESP";
export const FILES_SELECTED = "FILES_SELECTED";
export const Get_Question_Resp = "Get_Question_Resp";
export const GET_QUEST_ATTR_RESP = "GET_QUEST_ATTR_RESP";
export const GET_FILTER_QUES_RESP = "GET_FILTER_QUES_RESP";
export const GET_LETTER_INTENT_RESP = "GET_LETTER_INTENT_RESP";
export const GET_SINGLE_TEMPLATE_RESP = "GET_SINGLE_TEMPLATE_RESP";
export const EDIT_QUIZ_RESP = "EDIT_QUIZ_RESP";
export const EDIT_JOB_RESP = "EDIT_JOB_RESP";
export const UPDATE_JOB_RESP = "UPDATE_JOB_RESP";
export const SELECTED_SKILLS_DATA = "SELECTED_SKILLS_DATA";
export const GET_ALL_JOBS_RESP = "GET_ALL_JOBS_RESP";
export const GET_RECOMMENDED_JOBS_RESP = "GET_RECOMMENDED_JOBS_RESP";
export const APPLY_JOB_RESP = "APPLY_JOB_RESP";
export const JOB_DETAIL_RESP = "JOB_DETAIL_RESP";
export const JOB_ADD_RESP = "JOB_ADD_RESP";
export const GET_LOCATION_RESP = "GET_LOCATION_RESP";
export const GET_SKILLS_FILTER_RESP = "GET_SKILLS_FILTER_RESP";
export const SEARCH_DATA = "SEARCH_DATA";
export const GET_CAND_PROFILE_CARDS_RESP = "GET_CAND_PROFILE_CARDS_RESP";
export const GET_DESIGNATION_RESP = "GET_DESIGNATION_RESP";
export const EDIT_PROFILE_RESP = "EDIT_PROFILE_RESP";
export const ATTEMPT_QUIZ_RESP = "ATTEMPT_QUIZ_RESP";
export const SHOW_QUIZ_ANS_RESP = "SHOW_QUIZ_ANS_RESP";
export const SELECT_ANSWER_RESP = "SELECT_ANSWER_RESP";
export const SEND_ANSWER_RESP = "SEND_ANSWER_RESP";
export const START_QUIZ_RESP = "START_QUIZ_RESP";
export const FINAL_SUBMIT_RESP = "FINAL_SUBMIT_RESP";
export const QUIZ_RESULT = "QUIZ_RESULT";
export const GET_APPLIED_JOBS_RESP = "GET_APPLIED_JOBS_RESP";
export const LIKE_SAVE_JOB_RESP = "LIKE_SAVE_JOB_RESP";
export const JOB_STATUS_RESP = "JOB_STATUS_RESP";
export const CHANGE_PASS_RESP = "CHANGE_PASS_RESP";
export const SET_PASS_RESP = "SET_PASS_RESP";
export const JOB_TYPES_RESP = "JOB_TYPES_RESP";
export const JOB_TYPE_DETAILS_RESP = "JOB_TYPE_DETAILS_RESP";
export const JOB_DESC_RESP = "JOB_DESC_RESP";
export const GET_COMPANY_CATEGORY_RESP = "GET_COMPANY_CATEGORY_RESP";
export const GET_STREAMS_RES = "GET_STREAMS_RES";
export const GET_COURSE_DURATION_RESP = "GET_COURSE_DURATION_RESP";
//get_years
export const GET_YEARS_RESP = "GET_YEARS_RESP";
export const GET_COMPANY_SUB_CATEGORY_RESP = "GET_COMPANY_SUB_CATEGORY_RESP";
export const GET_COUNTRY_RESP = "GET_COUNTRY_RESP";
export const GET_STD_RESP = "GET_STD_RESP";
export const GET_STATE_RESP = "GET_STATE_RESP";
export const GET_CITY_RESP = "GET_CITY_RESP";
export const GET_CITIES_RESP = "GET_CITIES_RESP";
export const GET_PROBABLE_CANDIDATES_RESP = "GET_PROBABLE_CANDIDATES_RESP";
export const GET_CITIES_FILTER_RESP = "GET_CITIES_FILTER_RESP";
export const GET_COUNTRY_CATEGORY_RESP = "GET_COUNTRY_CATEGORY";
export const GET_STATE_CATEGORY_RESP = "GET_STATE_CATEGORY";
export const GET_CITY_CATEGORY_RESP = "GET_CITY_CATEGORY";
export const GET_COMPANY_APPLIED_JOBS_RESP = "GET_COMPANY_APPLIED_JOBS_RESP";
export const GET_COUNTRY_CATEGORY_P_RESP = "GET_COUNTRY_P_CATEGORY";
export const GET_STATE_CATEGORY_P_RESP = "GET_STATE_P_CATEGORY";
export const GET_CITY_CATEGORY_P_RESP = "GET_CITY_P_CATEGORY";
export const GET_JOB_CATEGORIES_RESP = "GET_JOB_CATEGORIES_RESP";
export const GET_ALL_MEMBERS_RESP = "GET_ALL_MEMBERS_RESP";
export const GET_OFFER_LETTERS_RESP = "GET_OFFER_LETTERS_RESP";
export const CHANGE_JOB_STATUS_RESP = "CHANGE_JOB_STATUS_RESP";
export const SCHEDULE_INTERVIEW_RESP = "SCHEDULE_INTERVIEW_RESP";
export const GET_CURRENT_PLAN_FEATURES_RESP = "GET_CURRENT_PLAN_FEATURES_RESP";
export const DELETE_USER_CONFIRMATION = "DELETE_USER_CONFIRMATION";
export const SITE_DATA = "SITE_DATA";
export const GET_COUNT_JOBS_RESP = "GET_COUNT_JOBS_RESP";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const DISABLE_STATE_TEXT = "reject";

export const ACCEPT_REJECT = {
  1: [
    "interview_scheduled",
    "interview_given",
    "loi_sent",
    "offer_letter_sent",
  ],
  2: ["quiz_attempted", "quiz_passed"],
};
export const GET_EDUCATION_LEVELS_RESP = "GET_EDUCATION_LEVELS_RESP";
export const GET_COURSES_RESP = "GET_COURSES_RESP";
export const GET_SPECIALIZATIONS_RESP = "GET_SPECIALIZATIONS_RESP";
export const GET_GRADES_RESP = "GET_GRADES_RESP";
export const UPLOAD_PROFILE_IMAGE_RESP = "UPLOAD_PROFILE_IMAGE_RESP";
export const REMOVE_PROFILE_DOCUMENT_RESP = "REMOVE_PROFILE_DOCUMENT_RESP";
export const DELETE_CAREER_PROFILE_FILE_RESP =
  "DELETE_CAREER_PROFILE_FILE_RESP";
export const GET_COMPANY_DETAIL_RESP = "GET_COMPANY_DETAIL_RESP";
export const GET_CAMPUS_DETAIL_RESP = "GET_CAMPUS_DETAIL_RESP";
export const GET_LIST_ALL_JOB_RESP = "GET_LIST_ALL_JOB_RESP";
export const GET_SALARIES_RESP = "GET_SALARIES_RESP";
export const GET_WORK_EXPERIENCES_RESP = "GET_WORK_EXPERIENCES_RESP";
export const GET_USER_CURRENT_PLAN_DETAILS_RESP =
  "GET_USER_CURRENT_PLAN_DETAILS_RESP";
export const GET_NEW_PLAN_RESP = "GET_NEW_PLAN_RESP";
export const FEATURE_PLAN_RESP = "FEATURE_PLAN_RESP";
export const GET_ALL_JOBS_DASHBOARD_RES = "GET_ALL_JOBS_DASHBOARD_RES";
export const GET_CANDIDATE_TYPE_RES = "GET_CANDIDATE_TYPE_RES";
export const GET_QUIZZES_DASHBOARD_RESP = "GET_QUIZZES_DASHBOARD_RESP";
export const MY_JOBDESCRIPTION_RESP = "MY_JOBDESCRIPTION_RESP";
export const GET_JOBDESCDETAILS_RESP = "GET_JOBDESCDETAILS_RESP";
export const CREATE_QUIZ_NEW_RESP = "CREATE_QUIZ_NEW_RESP";
export const ADD_EDIT_JOB_RESP = "ADD_EDIT_JOB_RESP";
export const JOB_STATUS_UPDATE_RESP = "JOB_STATUS_UPDATE_RESP";
export const GET_DRAFT_JOBS_RESP = "GET_DRAFT_JOBS_RESP";
export const ADD_EDIT_JOB_MAPPING_RESP = "ADD_EDIT_JOB_MAPPING_RESP";
export const REGISTER_SUCCESS_RESP = "REGISTER_SUCCESS_RESP";
export const GET_QUIZ_DETAIL_RESP = "GET_QUIZ_DETAIL_RESP";
export const GET_ALL_TAGS_RESP = "GET_ALL_TAGS_RESP";
export const GET_TAGS_BY_TYPE_RESP = "GET_TAGS_BY_TYPE_RESP";
export const GET_JOBDETAILSWITHMAP_RESP = "GET_JOBDETAILSWITHMAP_RESP";
export const APPLIED_QUIZ_DETAILS_RESP = "APPLIED_QUIZ_DETAILS_RESP";
export const GET_JOBDETAIL_RESP = "GET_JOBDETAIL_RESP";
export const GET_CURRENT_USER_JOB_TYPES_RESP =
  "GET_CURRENT_USER_JOB_TYPES_RESP";
export const GET_INDUSTRIES_RESP = "GET_INDUSTRIES_RESP";
export const GET_INVOICE_RESP = "GET_INVOICE_RESP";
export const GET_SEARCH = "GET_SEARCH";
export const PROBABLE_CANDIDATENOTI_RESP = "PROBABLE_CANDIDATENOTI_RESP";
export const GET_ACCOUNT_RESP = "GET_ACCOUNT_RESP";
export const GET_COUNTRY_CALLING_CODES_RESP = "GET_COUNTRY_CALLING_CODES_RESP";

export const GET_STD_CALLING_CODES_RESP = "GET_STD_CALLING_CODES_RESP";
export const GET_SMS_TEMPLATES_RESP = "GET_SMS_TEMPLATES_RESP";
export const GET_INSERT_SKILL_RESP = "GET_INSERT_SKILL_RESP";
export const GET_TEMPLATE_LIST_RESP = "GET_TEMPLATE_LIST_RESP";
export const VALIDATE_TOTAL_QUESTION_RESP = "VALIDATE_TOTAL_QUESTION_RESP";
/* Api Response Constants */

/* Validation Rules */
export const Email_Validation_Regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const Mobile_Validation_Regex = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
export const Password_Validation_Regex = /^[A-Za-z0-9$%_@#]{2,16}$/;
export const Password_Validation_Message =
  "Password should be atleast 8 characters long contains only special characters $%_@#";
export const LettersOnly = /^[A-Za-z_ ]{1,}$/;
export const Numbers_Only_Regex = /^[0-9]{1,}$/;
export const Pincode_Regex = /^[1-9][0-9]{5}$/;
/* Validation Rules */

/* Social Login Config */
export const FB_Login_Success_CallBack_URL = "/fb-login-success";
export const FB_Login_Cancel_CallBack_URL = "/fb-login-cancel";
export const FB_APP_ID = "230936089134330";
export const FB_Secret_key = "cc35a88799418e1934b88898cf418533";

export const Google_Success_CallBack_URL = "/google-login-success";
export const Google_Cancel_CallBack_URL = "/google-login-cancel";
export const Google_Client_ID = "230936089134330";
export const Google_Secret_key = "";
/* Social Login Config */

/* Messages */
export const LIKE_JOB_MSG = "Congrats! You have liked this job";
export const SAVE_JOB_MSG = "You have saved this job";
/* Messages */
