import { GET_STATS } from '../../config/constants';

const initialState = {
    data: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STATS:
            return {
                ...state,
                data: action.data
            };
        default:
            return state;
    }
}
