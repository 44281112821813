import React, { useEffect } from 'react'

const Loading = ({ className, size }) => {

	useEffect(async () => {
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		}
	}, []);

	return (
		<div className={`${className} text-center`}>
			<div
				class='spinner-border spinner-border-sm'
				style={{ height: size, width: size }}
				role='status'
			>
				<span class='visually-hidden'>Loading...</span>
			</div>
		</div>
	);
};

export default Loading
