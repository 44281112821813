import { GET_SEARCH } from '../../config/constants';

const initialState = {
	search: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_SEARCH:
			return {
				...state,
                search: action.data
			};
		default:
			return state;
	}
}
