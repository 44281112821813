import React,{ Component} from 'react';
import './assets/scss/bootstrap.scss';
import './assets/line-awesome-1.3.0/1.3.0/css/line-awesome.min.css';
import './assets/css/style.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Routes } from './routes/routes';
import { APP_NAME } from './config/constants';
import { Helmet } from "react-helmet";
// import { useContext} from 'react';

/* slick slider */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { createContext, React } from 'react';
/* slick slider */






export const MessageNotification = React.createContext();
class App extends Component {
	
	
	componentDidMount() {
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props?.location?.pathname !== prevProps?.location?.pathname) {
			window.scrollTo(0, 0);
		}
	}

    state={
		updateVal:false
	}
	update=()=>{
this.setState({
	updateVal:!this.state.updateVal
})
	}
	render() {
		
		
		return (
			<MessageNotification.Provider value={{ messageNotificationValue: this.state.updateVal, update: this.update.bind(this) }}>
			<div className='App'>
				<Helmet>
					<title>{APP_NAME}</title>
					<meta property="og:title" content={APP_NAME} />
                    <meta property="og:description" content="This is Online Erukrut system" />
                    <meta name="description" content="This is Online Erukrut system"></meta>
                     <meta name="keyword" content="JOBS, RECURUITMENT"></meta>
				</Helmet>
				<Router>
					<Switch>

						{Routes.map((route, i) => (
							<Route
								key={i}
								exact={route.exact}
								path={route.path}
								component={route.component}
								// onUpdate={() => window.scrollTo(0, 0)}
							/>
						))}


					</Switch>

				</Router>
			</div>
			</MessageNotification.Provider>
		);
	}
}

export default App;
