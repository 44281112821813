import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import jobreducer from "./jobreducer";
import getJobs from "./getJobs";
import getStats from "./getStats";
import getSearch from "./getSearch";
import appliedcandidatesreducer from "./appliedcandidatesreducer";

// Aggreating all the reducers defined
const rootReducer = combineReducers({
  common: commonReducer,
  jobreducer: jobreducer,
  getJobs: getJobs,
  getStats: getStats,
  appliedcandidatesreducer: appliedcandidatesreducer,
  search: getSearch,
});

export default rootReducer;
