import { GET_JOBS } from '../../config/constants';

const initialState = {
	data: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_JOBS:
			return {
				...state,
                data: action.data
			};
		default:
			return state;
	}
}
