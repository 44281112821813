import { GET_COMPANY_APPLIED_JOBS_RESP } from "../../config/constants";

const initialState = {
	comp_applied_jobs_resp: null,
};


export default function (state = initialState, action) {

	switch (action.type) {
		case GET_COMPANY_APPLIED_JOBS_RESP:
			return {
				...state,
				comp_applied_jobs_resp: action.data,
			};
		default:
			return state;
	}
}
