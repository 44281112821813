import {
    GET_JOB_FORM_DATA
} from '../../config/constants';

const initialState = {
	jobformdata: [],
};


export default function (state = initialState, action) {

	switch (action.type) {
		case GET_JOB_FORM_DATA:
			return {
				...state,
				jobformdata: action.data,
			};
		default:
			return state;
	}
}

