import { lazy } from "react";
/**
 * test
 */
export const CommonRoutes = [
  {
    path: "/checkout/:id/:string",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/CheckOutNew")),
    basename: process.env.PUBLIC_URL,
  },
 { path: "/campus-sms",
   title: "Campus Sms",
   component: lazy(() => import("../views/pages/Sms/Sms")),
   basename: process.env.PUBLIC_URL,},

  {
    path: "/campus-emails",
    title: "Campus Email",
    component: lazy(() => import("../views/pages/Emails/Emails")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campus-jobs-applied",
    title: "Campus Job Applied",
    component: lazy(() => import("../views/pages/JobApplied/CampusJobApplied")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/checkoutproccess/",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/CheckOutProcess")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/paymentProcess/",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/PaymentProcess")),
    basename: process.env.PUBLIC_URL,
  },
  // {
  //   path: "/paymentSuccess/",
  //   title: "checkout",
  //   component: lazy(() => import("../views/pages/CheckOut/PaymentSuccess")),
  //   basename: process.env.PUBLIC_URL,
  // },
  {
    path: "/failure/",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/PaymentFail")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/thank-you",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/PaymentSuccess")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/thank-yous",
    title: "thanku",
    component: lazy(() => import("../views/pages/Thanku/Thanku")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/successful",
    title: "JobSuccess",
    component: lazy(() => import("../views/pages/JobSuccess/JobSuccess")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/change-password",
    title: "ChangePassword",
    component: lazy(() =>
      import("../views/pages/ChangePassword/ChangePassword")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/notifications",
    title: "Notifications",
    component: lazy(() =>
      import("../views/pages/Notification/showAllNotification")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/Register-success",
    exact: true,
    component: lazy(() => import("../views/pages/RegisterSuccess/Success")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-sms/:jobid?",
    title: "Send SMS",
    exact: true,
    component: lazy(() => import("../views/pages/Recruiter/SendSms")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-email/:jobid?",
    title: "Send Email",
    exact: true,
    component: lazy(() => import("../views/pages/Recruiter/SendEmail")),
    basename: process.env.PUBLIC_URL,
  },
];

export const AuthRoutes = [
  {
    path: "/login",
    exact: true,
    component: lazy(() => import("../views/Auth/Login/Login")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/mobilepayment/:order_id/:token/:amount",
    exact: true,
    component: lazy(() => import("../views/mobilePayment")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/forgot-password",
    exact: true,
    component: lazy(() => import("../views/Auth/Login/ForgetPassword")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/recruiter-signup/:state?",
    exact: true,
    component: lazy(() => import("../views/Auth/Signup/RecruiterSignup")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidate-signup/:state?",
    exact: true,
    component: lazy(() => import("../views/Auth/Signup/CandidateSignup")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/Register-success",
    exact: true,
    component: lazy(() => import("../views/pages/RegisterSuccess/Success")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/campus-signup/:state?",
    exact: true,
    component: lazy(() => import("../views/Auth/Signup/CampusSignup")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/assessment-round/:id",
    title: "Assessment Round",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/AssessmentRound")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/start-assessment/:quiz_id/:user_id",
    title: "Start Assessment",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/StartAssessment")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/assessment-success",
    title: "Assessment Success",
    exact: true,
    component: lazy(() =>
      import("../views/pages/Assessment/AssessmentSuccess")
    ),
    basename: process.env.PUBLIC_URL,
  },
];

export const CompanyRoutes = [
  {
    path: "/",
    title: "Recruiter",
    exact: true,
    component: lazy(() => import("../views/pages/Feed/Feed")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/recruiter-messages",
    title: "Recruiter Messages",
    component: lazy(() => import("../views/pages/Messages/Messagesnew")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/emails",
    title: "Recruiter Messages",
    component: lazy(() => import("../views/pages/Emails/Emails")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/get_sms",
    title: "Recruiter Messages",
    component: lazy(() => import("../views/pages/Sms/Sms")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-campus-invite",
    title: "Send Campus Invitation",
    exact: true,
    component: lazy(() => import("../views/pages/Recruiter/SendCampusInvite")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-company-message",
    title: "Send Company Message",
    exact: true,
    component: lazy(() => import("../views/pages/Messages/CompanyMessage")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/company-messages",
    title: "Company Messages",
    exact: true,
    component: lazy(() => import("../views/pages/Messages/CompanyMessage1")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-announcements",
    title: "Send Announcement",
    exact: true,
    component: lazy(() =>
      import("../views/pages/SendAnnouncement/SendAnnoucement")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/jobstats",
    title: "Jobstats",
    exact: true,
    component: lazy(() => import("../views/pages/JobStats/jobStats")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/ardex",
    title: "CandidateSearch",
    exact: true,
    component: lazy(() =>
      import("../views/pages/CandidateList/CandidateSearch")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/add-access-level",
    title: "Add Access Level",
    exact: true,
    component: lazy(() => import("../views/pages/AccessLevel/AddAccessLevel")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-job",
    title: "AddJob",
    component: lazy(() => import("../views/pages/AddJob/AddJob")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-job/:id",
    title: "Add Job",
    component: lazy(() => import("../views/pages/AddJob/EditJob")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/boost-job/:id",
    title: "Boost Job",
    component: lazy(() => import("../views/pages/AddJob/BoostJob")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-quiz",
    title: "AddQuiz",
    component: lazy(() => import("../views/pages/AddQuiz/AddQuiz")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-offer-letter",
    title: "add offer letter",
    component: lazy(() => import("../views/pages/OfferLetter/AddOfferLetter")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-offer-letter/:id",
    title: "edit offer letter",
    component: lazy(() => import("../views/pages/OfferLetter/EditOfferLetter")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/recruiter/:slug?",
    title: "recruiter",
    component: lazy(() => import("../views/pages/Recruiter/RecruiterHome")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/plans",
    title: "select jobs plan",
    component: lazy(() => import("../views/pages/SelectPlan/SelectPlan")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/view-assessment-result",
    title: "View Assessment Result",
    component: lazy(() => import("../views/pages/Assessment/Assessment")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/expand-plan/:id/:current?",
    title: "expend job Plan",
    component: lazy(() => import("../views/pages/ExpendPlan/ExpendPlan")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/expand-pack",
    title: "expend job Plan",
    component: lazy(() => import("../views/pages/ExpendPlan/ExpandPlanNew")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/payment-information",
    title: "PaymentInformation",
    exact: true,
    component: lazy(() =>
      import("../views/pages/PaymentInformation/PaymentInformation")
    ),
    basename: process.env.PUBLIC_URL,
  },
  // {
  //   path: "/checkout/:id/:string",
  //   title: "checkout",
  //   component: lazy(() => import("../views/pages/CheckOut/CheckOutNew")),
  //   basename: process.env.PUBLIC_URL,
  // },
  {
    path: "/billing-details/:id?",
    title: "checkout",
    component: lazy(() =>
      import("../views/pages/BillingDetails/BillingDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/account-billings/:id?",
    title: "CandidateAccount",
    exact: true,
    component: lazy(() =>
      import("../views/pages/BillingDetails/AccountBillingDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-company-profile/:id",
    title: "EditCompanyProfile",
    component: lazy(() =>
      import("../views/pages/EditCompanyProfile/EditCompanyProfile")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/company-profile",
    title: "CompanyProfile",
    component: lazy(() =>
      import("../views/pages/CompanyProfile/CompanyProfile")
    ),
    basename: process.env.PUBLIC_URL,
  },
  // {
  // 	path: '/notification/:id/:senderid',
  // 	title: 'Show Notification',
  // 	component: lazy(() => import('../views/pages/Notification/singleNotification')),
  // 	basename: process.env.PUBLIC_URL,
  // },
  {
    path: "/add-question",
    title: " add question",
    component: lazy(() => import("../views/pages/AddQuestion/AddQuestion")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-question/:id",
    title: " edit question",
    component: lazy(() => import("../views/pages/EditQuestion/EditQuestion")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-questionold/:id",
    title: " edit question",
    component: lazy(() => import("../views/pages/EditQuestion/EditQuestionOld")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-testimonial",
    title: " add testimonial",
    component: lazy(() => import("../views/pages/Testimonial/AddTestimonial")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-member",
    title: " add member",
    component: lazy(() => import("../views/pages/Member/AddMember")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/view-candidate",
    title: " View Candidate",
    component: lazy(() => import("../views/pages/CandidateList/CandidateList")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-quiz/:id",
    title: " Edit Quize",
    exact: true,
    component: lazy(() => import("../views/pages/EdtQuiz/EditQuiz")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-quizold/:id",
    title: " Edit Quize",
    exact: true,
    component: lazy(() => import("../views/pages/EdtQuiz/EditQuizOld")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/letter-intent",
    title: "Create Letter of Intent",
    component: lazy(() =>
      import("../views/pages/LetterIntent/AddLetterIntent")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-loi/:id?",
    title: "Edit Letter of Intent",
    component: lazy(() =>
      import("../views/pages/LetterIntent/AddLetterIntent")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-template/:id?",
    title: "AddTemplate",
    component: lazy(() => import("../views/pages/AddTemplate/AddTemplate")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/Quizes",
    title: " Quizes",
    component: lazy(() => import("../views/pages/Quizes/Quizes")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/jobpreview",
    title: "jobpreview",

    component: lazy(() => import("../views/pages/JobPreview/JobPreview")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/applied-jobs",
    title: "jobpreview",

    component: lazy(() =>
      import("../views/pages/CadidateAppliedJobs/CadidateAppliedJobs")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/uiHome",
    title: "UiHome",

    component: lazy(() => import("../views/pages/HomeDashboard/UiHome")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/homeDashboard",
    title: "HomeDashboard",

    component: lazy(() => import("../views/pages/HomeDashboard/HomeDashboard")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/adQuize",
    title: "AdQuize",

    component: lazy(() => import("../views/pages/AdQuiz/AdQuize")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/addAnouncement",
    title: "AddAnouncement",

    component: lazy(() =>
      import("../views/pages/AddAnouncement/AddAnouncement")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campusDashboard",
    title: "CampusDashboard",

    component: lazy(() =>
      import("../views/pages/CampusDashboard/CampusDashboard")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campusDashboard2",
    title: "CampusDashboard2",

    component: lazy(() =>
      import("../views/pages/CampusDashboard/CampusDashboard2")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-jobDescription",
    title: "Add Job Description",

    component: lazy(() =>
      import("../views/pages/JobDescription/AddJobDescription")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-jobDescription/:jid",
    title: "Add Job Description",

    component: lazy(() =>
      import("../views/pages/JobDescription/AddJobDescription")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-jobDescriptions",
    title: "Add Job Description",

    component: lazy(() =>
      import("../views/pages/JobDescription/AddJobDescriptionOld")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/quiz-result/:id/:user_id",
    title: "Quiz Result",

    component: lazy(() => import("../views/pages/QuizResult/QuizResult")),
    basename: process.env.PUBLIC_URL,
  },
];

export const CandidateRoutes = [
  {
    path: "/candidates",
    title: "TotalCandidates",
    component: lazy(() =>
      import("../views/pages/TotalCandidates/TotalCandidates")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/send-message/:id?/:j_id?/:cname?",
    title: "Send Message To Recruiter",
    exact: true,
    component: lazy(() => import("../views/pages/Messages/SendMsgToRecruiter")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidatecheckout/:id?",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/checkOut")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/billing-detail/:id?",
    title: "checkout",
    component: lazy(() =>
      import("../views/pages/BillingDetails/BillingDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/account-billing/:id?",
    title: "CandidateAccount",
    exact: true,
    component: lazy(() =>
      import("../views/pages/BillingDetails/AccountBillingDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/payment",
    title: "PaymentInformation",
    exact: true,
    component: lazy(() =>
      import("../views/pages/PaymentInformation/PaymentInformation")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidate-plans",
    title: "select jobs plan",
    component: lazy(() => import("../views/pages/CandidatePlans/SelectPlan")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/BuyPlans",
    title: "Buy Plans",
    component: lazy(() => import("../views/pages/CandidatePlans/BuyPlans")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/expand-candidate-pack",
    title: "expend job Plan",
    component: lazy(() => import("../views/pages/ExpendPlan/ExpandCandidatePlan")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/expand-plans/:id/:current?",
    title: "expend job Plan",
    component: lazy(() => import("../views/pages/ExpendPlan/ExpendPlan")),
  },
  {
    path: "/candidate-messages",
    title: "Candidate Messages",
    component: lazy(() => import("../views/pages/Messages/Messagesnew")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/totalCandidates1",
    title: "TotalCandidates1",
    component: lazy(() =>
      import("../views/pages/HomeDashboard/TotalCandidates1")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/totalCandidates2",
    title: "TotalCandidates2",
    component: lazy(() =>
      import("../views/pages/HomeDashboard/TotalCandidates2")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidatesStats",
    title: "CandidatesStats",
    component: lazy(() =>
      import("../views/pages/HomeDashboard/CandidatesStats")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidateAccount",
    title: "CandidateAccount",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/CandidateAccount")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/my-jobs/:q?",
    title: "My Jobs",
    component: lazy(() => import("../views/pages/MyJobs/MyJobs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/recruiter-jobs/:q?",
    title: "My Jobs",
    component: lazy(() => import("../views/pages/MyJobs/RecruiterJobs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/draft-jobs",
    title: "My Jobs",
    component: lazy(() => import("../views/pages/MyJobs/DraftJobs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/total-candidate",
    title: "TotalCandidate",
    exact: true,
    component: lazy(() =>
      import("../views/pages/TotalCandidate/TotalCandidate")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/applied-candidates",
    title: "Applied Candidates", 
    exact: true,
    component: lazy(() =>
      import("../views/pages/AppliedCandidates/AppliedCandidates")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/PersonalDetails",
    title: "PersonalDetails",
    component: lazy(() =>
      import("../views/pages/PersonalDetails/PersonalDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/profile",
    title: "Profile",
    component: lazy(() =>
      import("../views/pages/PersonalDetails/PersonalDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/profile-preview",
    title: "Profile Preview",
    component: lazy(() =>
      import("../views/pages/ProfilePreview/ProfilePreview")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/edit-profile",
    title: "Edit Profile",
    component: lazy(() =>
      import("../views/pages/PersonalDetails/EditPersonalDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-announcements",
    title: "Send Announcements",
    component: lazy(() =>
      import("../views/pages/SendAnnouncement/SendAnnoucement")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/apply-job/:id?/:job_title?/:company_name?/:logo?",
    title: "Apply Job",
    exact: true,
    component: lazy(() => import("../views/pages/ApplyJob/ApplyJob")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/apply-quiz/:id/:qid",
    title: "Apply Job",
    exact: true,
    component: lazy(() => import("../views/pages/Quiz/ApplyQuiz")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/preview-quiz/:qid",
    title: "Preview Job",
    exact: true,
    component: lazy(() => import("../views/pages/Quiz/PreviewQuiz")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/recruiter-start-quiz/:quiz_id",
    title: "Preview Job",
    exact: true,
    component: lazy(() => import("../views/pages/StartQuize/RecruiterStartQuiz")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/quiz-success",
    title: "Quiz Success",
    exact: true,
    component: lazy(() => import("../views/pages/QuizSuccess/QuizSuccess")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/recruiter-quiz-success",
    title: "Quiz Success",
    exact: true,
    component: lazy(() =>
      import("../views/pages/QuizSuccess/RecruiterQuizSuccess")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/start-quiz/:job_id/:quiz_id/:job_app_id",
    title: "Start Quiz",
    exact: true,
    component: lazy(() => import("../views/pages/StartQuize/StartQuize")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/start-quiz/:job_id/:quiz_id",
    title: "StartQuize",
    exact: true,
    component: lazy(() => import("../views/pages/StartQuize/StartQuize")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/start-recruiter-quiz/:job_id/:quiz_id/:job_app_id",
    title: "StartQuize",
    exact: true,
    component: lazy(() =>
      import("../views/pages/StartQuize/StartRecruiterQuiz")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/Show-quiz/:user_id/:job_id/:quiz_id/:quiz_result_id",
    title: "StartQuize",
    exact: true,
    component: lazy(() => import("../views/pages/StartQuize/ShowQuizeResult")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/quiz-section/:job_id/:job_title/:company_name/:logo",
    title: "Quiz Section",
    exact: true,
    component: lazy(() => import("../views/pages/QuizSection/QuizSection")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/assessment",
    title: "Assessment",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/Assessment")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidateAssessment",
    title: "Candidate Assessment",
    exact: true,
    component: lazy(() =>
      import("../views/pages/Assessment/CandidateAssessment")
    ),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/contectUs",
    title: "ContectUs",
    exact: true,
    component: lazy(() => import("../views/pages/ContectUs/ContectUs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/homeNotify5",
    title: "HomeNotify5",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/HomeNotify5")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/recruiterAccount",
    title: "RecruiterAccount",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/RecruiterAccount")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/uiHome",
    title: "UiHome",
    exact: true,
    component: lazy(() => import("../views/pages/HomeDashboard/UiHome")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/candidate-profile/:id",
    title: "Profile",
    component: lazy(() =>
      import("../views/pages/ProfilePreview/ProfilePreview")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/my-account",
    title: "My Account",
    exact: true,
    component: lazy(() => import("../views/pages/Assessment/RecruiterAccount")),
    basename: process.env.PUBLIC_URL,
  },
];

/* Campus Routes */

export const CampusRoutes = [
  {
    path: "/campus/:slug?",
    title: "Campus",
    exact: true,
    component: lazy(() => import("../views/pages/Campus/CampusHome")),
    basename: process.env.PUBLIC_URL,
  },
  // {
  //   path: "/campus-plans",
  //   title: "Compus plans",
  //   exact: true,
  //   component: lazy(() => import("../views/pages/Campus/CampusPlans")),
  //   basename: process.env.PUBLIC_URL,
  // },
  {
    path: "/campus-stats",
    title: "Campus Stats",
    exact: true,
    component: lazy(() => import("../views/pages/Campus/CampusStats")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-company-invite",
    title: "Send Company Invitation",
    exact: true,
    component: lazy(() => import("../views/pages/Campus/SendCompanyInvite")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/campus-messages",
    title: "Campus Messages",
    exact: true,
    component: lazy(() => import("../views/pages/Messages/Messages1")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/send-campus-message",
    title: "Send Campus Message",
    exact: true,
    component: lazy(() => import("../views/pages/Messages/Messages2")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/messages3",
    title: "Messages3",

    component: lazy(() => import("../views/pages/Messages/Messages3")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-coordinator",
    title: "Add Coordinator",
    component: lazy(() => import("../views/pages/Member/AddMember")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/add-campus-access-level",
    title: "Add Access Level",
    exact: true,
    component: lazy(() =>
      import("../views/pages/AccessLevel/AddCampusAccessLevel")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campus-plans",
    title: "select jobs plan",
    component: lazy(() => import("../views/pages/CampusPlans/SelectPlan")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/expand-campus-pack",
    title: "select jobs plan",
    component: lazy(() => import("../views/pages/ExpendPlan/ExpandPlanNew")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/expand-campus-plan/:id/:current?",
    title: "expend job Plan",
    component: lazy(() => import("../views/pages/ExpendPlan/ExpendCampusPlan")),
  },

  {
    path: "/campus-checkout/:id?",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/checkOut")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campas-checkout/:id/:string",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/CampusCheckOut")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campus-pay/:id?",
    title: "checkout",
    component: lazy(() => import("../views/pages/CheckOut/CheckOut2")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/campus-billing-details",
    title: "checkout",
    component: lazy(() =>
      import("../views/pages/BillingDetails/BillingDetails")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campus-payment-information",
    title: "PaymentInformation",
    exact: true,
    component: lazy(() =>
      import("../views/pages/PaymentInformation/PaymentInformation")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campus-thank-you",
    title: "thanku",
    component: lazy(() => import("../views/pages/Thanku/Thanku")),
    basename: process.env.PUBLIC_URL,
  },
];

/* guest routes */

export const GuestRoutes = [
  {
    path: "/search/:term?/:data?",
    title: "Search",
    exact: true,
    component: lazy(() => import("../views/pages/Search/Search")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/",
    title: "Home",
    exact: true,
    component: lazy(() => import("../views/pages/Feed/Feed")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/job/:id/:title",
    title: "Job Detail",
    exact: true,
    component: lazy(() => import("../views/pages/JobDetail/JobDetail")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/contact-us",
    title: "Contact Us",
    component: lazy(() => import("../views/pages/ContactUs/ContactUs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/set-password/:token",
    title: "Set Password",
    component: lazy(() =>
      import("../views/pages/ChangePassword/SetPassword")
    ),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/about-us",
    title: "About",
    component: lazy(() => import("../views/pages/ContactUs/AboutUs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/terms-conditions",
    title: "Terms & Conditions",
    component: lazy(() => import("../views/pages/ContactUs/TermsConditions")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/privacy-policy",
    title: "Privacy Policy",
    component: lazy(() => import("../views/pages/ContactUs/PrivacyPolicy")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/refund-policy",
    title: "refund Policy",
    component: lazy(() => import("../views/pages/ContactUs/RefundPolicy")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/company/:id",
    title: "Jobs",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/CompanyPage")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/campus/:id",
    title: "Jobs",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/CampusPage")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/all-companies",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/AllCompanies")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/all-campus",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/Campus/AllCampus")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/top-companies",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/TopCompanies")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/top-campuses",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/TopCampuses")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/featured-companies",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/FeaturesCompany")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/all-jobs",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/AllJobs")),
    basename: process.env.PUBLIC_URL,
  },
  {
    path: "/top-jobs",
    title: "Companies",
    exact: true,
    component: lazy(() => import("../views/pages/CompanyPage/TopJobs")),
    basename: process.env.PUBLIC_URL,
  },
];

export const CompanyTemplates = [
  {
    path: "/template/create-page",
    title: "CreatePage",
    component: lazy(() => import("../views/pages/CompanyTemplates/CreatePage")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/template/home",
    title: "HomePage",
    component: lazy(() => import("../views/pages/CompanyTemplates/HomePage")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/template/pages",
    title: "Pages",
    component: lazy(() => import("../views/pages/CompanyTemplates/Pages")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/template/logo",
    title: "Logo",
    component: lazy(() => import("../views/pages/CompanyTemplates/Logo")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/template/media",
    title: "Media",
    component: lazy(() => import("../views/pages/CompanyTemplates/Media")),
    basename: process.env.PUBLIC_URL,
  },

  {
    path: "/template/settings",
    title: "Settings",
    component: lazy(() => import("../views/pages/CompanyTemplates/Settings")),
    basename: process.env.PUBLIC_URL,
  },
];

export const Routes = [
  ...AuthRoutes,
  ...CompanyTemplates,
  {
    path: "",
    exact: true,
    title: "Home",
    component: lazy(() => import("../views/pages")),
    basename: process.env.PUBLIC_URL,
  },
];
