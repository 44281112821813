import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router,} from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/';
import Loading from './components/common/Loading';

ReactDOM.render(
	<Suspense fallback={<Loading/>}>
		<Provider store={store}>
				<App />
		</Provider>
	</Suspense>,
	document.getElementById('root'),
);