import {
  GET_TOP_COMPANY,
  GET_TOP_CAMPUS,
  GET_TOP_JOBS,
  OTP_Validate_Resp,
  Signup_Submit_Resp,
  GET_PLANS,
  Login_Resp,
  Forgot_Password_Resp,
  Update_Password_Resp,
  Update_Link_Password_Resp,
  OTP_Resp,
  Register_Resp,
  Add_Job_Resp,
  Add_Question_Resp,
  GET_USER_PROFILE_RESP,
  GET_RECOMMENDED_JOBS_RESP,
  GET_SEARCH_RESP,
  SELECTPLAN,
  SelectFeatures,
  SAVE_BILLING_DETAILS,
  GET_CREATE_QUIZ_RESP,
  GET_UPDATE_QUIZ_RESP,
  Update_Profile_Resp,
  OTP_RESEND_RESP,
  SOCIAL_LOGIN_RESP,
  GET_SKILLS_RESP,
  GET_QUIZ_RESP,
  GET_QUIZ_STATUS_RESP,
  SKIP_QUESTIONS_RESP,
  GET_LETTER_RESP,
  CREATE_LETTER_RESP,
  GET_TEMPLATE_RESP,
  CREATE_TEMPLATE_RESP,
  Get_Question_Resp,
  GET_QUEST_ATTR_RESP,
  GET_FILTER_QUES_RESP,
  GET_LETTER_INTENT_RESP,
  GET_SINGLE_TEMPLATE_RESP,
  EDIT_JOB_RESP,
  UPDATE_JOB_RESP,
  SELECTED_SKILLS_DATA,
  GET_ALL_JOBS_RESP,
  APPLY_JOB_RESP,
  EDIT_QUIZ_RESP,
  JOB_DETAIL_RESP,
  GET_LOCATION_RESP,
  GET_SKILLS_FILTER_RESP,
  SEARCH_DATA,
  GET_CAND_PROFILE_CARDS_RESP,
  GET_DESIGNATION_RESP,
  EDIT_PROFILE_RESP,
  ATTEMPT_QUIZ_RESP,
  SHOW_QUIZ_ANS_RESP,
  SELECT_ANSWER_RESP,
  SEND_ANSWER_RESP,
  START_QUIZ_RESP,
  FINAL_SUBMIT_RESP,
  QUIZ_RESULT,
  GET_APPLIED_JOBS_RESP,
  LIKE_SAVE_JOB_RESP,
  JOB_STATUS_RESP,
  CHANGE_PASS_RESP,
  SET_PASS_RESP,
  JOB_TYPES_RESP,
  JOB_TYPE_DETAILS_RESP,
  JOB_DESC_RESP,
  GET_COMPANY_CATEGORY_RESP,
  GET_COMPANY_SUB_CATEGORY_RESP,
  GET_COUNTRY_RESP,
  GET_STATE_RESP,
  GET_CITY_RESP,
  GET_CITIES_RESP,
  GET_PROBABLE_CANDIDATES_RESP,
  GET_CITIES_FILTER_RESP,
  GET_JOB_CATEGORIES_RESP,
  GET_ALL_MEMBERS_RESP,
  GET_OFFER_LETTERS_RESP,
  CHANGE_JOB_STATUS_RESP,
  SCHEDULE_INTERVIEW_RESP,
  GET_CURRENT_PLAN_FEATURES_RESP,
  GET_COUNTRY_CATEGORY_RESP,
  GET_STATE_CATEGORY_RESP,
  GET_CITY_CATEGORY_RESP,
  GET_COUNTRY_CATEGORY_P_RESP,
  GET_STATE_CATEGORY_P_RESP,
  GET_CITY_CATEGORY_P_RESP,
  DELETE_USER_CONFIRMATION,
  SITE_DATA,
  GET_COUNT_JOBS_RESP,
  GET_EDUCATION_LEVELS_RESP,
  GET_COURSES_RESP,
  GET_SPECIALIZATIONS_RESP,
  GET_GRADES_RESP,
  GET_COURSE_DURATION_RESP,
  GET_YEARS_RESP,
  UPLOAD_PROFILE_IMAGE_RESP,
  REMOVE_PROFILE_DOCUMENT_RESP,
  DELETE_CAREER_PROFILE_FILE_RESP,
  GET_COMPANY_APPLIED_JOBS_RESP,
  GET_COMPANY_DETAIL_RESP,
  GET_CAMPUS_DETAIL_RESP,
  GET_LIST_ALL_JOB_RESP,
  GET_SALARIES_RESP,
  GET_WORK_EXPERIENCES_RESP,
  GET_NEW_PLAN_RESP,
  FEATURE_PLAN_RESP,
  GET_ALL_JOBS_DASHBOARD_RES,
  GET_CANDIDATE_TYPE_RES,
  GET_QUIZZES_DASHBOARD_RESP,
  MY_JOBDESCRIPTION_RESP,
  GET_JOBDESCDETAILS_RESP,
  CREATE_QUIZ_NEW_RESP,
  ADD_EDIT_JOB_RESP,
  JOB_STATUS_UPDATE_RESP,
  GET_DRAFT_JOBS_RESP,
  ADD_EDIT_JOB_MAPPING_RESP,
  REGISTER_SUCCESS_RESP,
  GET_QUIZ_DETAIL_RESP,
  GET_ALL_TAGS_RESP,
  GET_TAGS_BY_TYPE_RESP,
  GET_JOBDETAILSWITHMAP_RESP,
  APPLIED_QUIZ_DETAILS_RESP,
  GET_JOBDETAIL_RESP,
  GET_CURRENT_USER_JOB_TYPES_RESP,
  GET_INDUSTRIES_RESP,
  GET_STREAMS_RES,
  GET_INVOICE_RESP,
  PROBABLE_CANDIDATENOTI_RESP,
  GET_ACCOUNT_RESP,
  GET_COUNTRY_CALLING_CODES_RESP,
  GET_SMS_TEMPLATES_RESP,
  JOB_ADD_RESP,
  GET_TEMPLATE_LIST_RESP,
  GET_STD_CALLING_CODES_RESP,
  VALIDATE_TOTAL_QUESTION_RESP,
 
} from "../../config/constants";

const initialState = {
  data: null,
  totalPriceOfExtendPlan: 0,
  SelectedFeature: [],
  selectedPlan: {},
  skillsdata: [],
  quizs: null,
  letterdata: null,
  letterintentdata: null,
  templatedata: null,
  selectedSKills: [],
  jobsData: [],
  add_job_data_rec: null,
  job_detail: null,
  location_res: [],
  skills_filter: null,
  search_data: null,
  cand_cards: null,
  designations_data: null,
  edit_profile_data: null,
  update_profile_res: null,
  attempt_quiz_res: null,
  select_answer_res: null,
  start_quiz_res: null,
  final_submit_res: null,
  quiz_result: null,
  quiz_result_status:null,
  applied_jobs_res: null,
  like_save_job_res: null,
  job_status_res: null,
  search_res: [],
  change_password_res: null,
 
  job_type_res: null,
  job_type_details_res: null,
  job_desc_res: null,
  comp_category_res: null,
  comp_sub_category_res: null,
  get_streams_res: null,
  country_res: null,
  state_res: null,
  city_res: null,
  citiesdata: null,
  cities_filter: null,
  get_job_cat_res: null,
  get_all_members_res: null,
  get_offer_letters_res: null,
  change_job_status_res: null,
  schedule_interview_res: null,
  curr_plan_feature_res: null,
  country_category_res: null,
  state_category_res: null,
  city_category_res: null,
  get_count_jobs_resp: null,
  get_education_levels_resp: null,
  get_courses_resp: null,
  get_specializations_resp: null,
  get_grades_resp: null,
  get_course_duration_resp: null,
  get_years_resp: null,
  upload_profile_image_resp: null,
  remove_profile_document_resp: null,
  delete_career_profile_file_resp: null,
  get_company_detail_resp: null,
  get_campus_detail_resp: null,
  get_list_all_job_resp: null,
  get_salaries_resp: null,
  get_work_experiences_resp: null,
  feature_plan_resp: null,
  get_all_jobs_dashboard_resp: null,
  get_candidate_type_resp: null,
  quizdata_resp: null,
  add_edit_job_resp: null,
  job_status_update_resp: null,
  get_draft_jobs_resp: null,
  add_edit_job_mapping_resp: null,
  register_success_resp: null,
  get_quiz_detail_resp: null,
  get_all_tags_resp: null,
  get_tags_by_type_resp: null,
  get_jobdetailswithmap_resp: null,
  applied_quiz_details_resp: null,
  get_current_user_job_types_resp: null,
  get_industries_resp: null,
  get_invoice_resp: [],
  probable_candidatenoti_res: null,
  get_account_resp: null,
  get_country_calling_codes_resp: null,
  get_std_calling_codes_resp: null,
  
  get_sms_templates_resp: null,
  get_template_list_resp: null,
  validate_total_question_resp : null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOP_COMPANY:
      return {
        ...state,
        //data: [{"company":"./assets/imgs/accenture.png"}]
        data: action.data,
      };
    case GET_TOP_CAMPUS:
      return {
        ...state,
        //data: [{"company":"./assets/imgs/accenture.png"}]
        data: action.data,
      };
    case GET_TOP_JOBS:
      return {
        ...state,
        //data: [{"company":"./assets/imgs/accenture.png"}]
        topJobs: action.data,
      };
    case GET_RECOMMENDED_JOBS_RESP:
      return {
        ...state,
        recommendedJobs: action.data,
      };

    case OTP_Validate_Resp:
      return {
        ...state,
        data: action.data,
      };
    case Signup_Submit_Resp:
      return {
        ...state,
        data: action.data,
      };
    case GET_PLANS:
      return {
        ...state,
        data: action.data,
      };
    case Register_Resp:
      return {
        ...state,
        data: action.data,
      };
    case OTP_Resp:
      return {
        ...state,
        data: action.data,
      };
    case Login_Resp:
      return {
        ...state,
        data: action.data,
      };
    case Forgot_Password_Resp:
      return {
        ...state,
        data: action.data,
      };
    case Update_Password_Resp:
      return {
        ...state,
        data: action.data,
      };

    case Update_Link_Password_Resp:
        return {
          ...state,
          data: action.data,
        };
    case Add_Job_Resp:
      return {
        ...state,
        data: action.data,
      };
    case Add_Question_Resp:
      return {
        ...state,
        data: action.data,
      };
    case GET_USER_PROFILE_RESP:
      return {
        ...state,
        data: action.data,
      };
    case GET_SEARCH_RESP:
      return {
        ...state,
        search_res: action.data,
      };
    case SELECTPLAN:
      return {
        ...state,
        selectedPlan: action.data,
      };
    case SelectFeatures:
      return {
        ...state,
        SelectedFeature: action.data,
      };
    case SAVE_BILLING_DETAILS:
      return {
        ...state,
      };
    case GET_CREATE_QUIZ_RESP:
      return {
        ...state,
        data: action.data,
      };
    case Update_Profile_Resp:
      return {
        ...state,
        update_profile_res: action.data,
      };
    case REMOVE_PROFILE_DOCUMENT_RESP:
      return {
        ...state,
        data: action.data,
      };
    case OTP_RESEND_RESP:
      return {
        ...state,
        data: action.data,
      };
    case SOCIAL_LOGIN_RESP:
      return {
        ...state,
        data: action.data,
      };

    case GET_SKILLS_RESP:
      return {
        ...state,
        skillsdata: action.data,
      };
    case GET_CITIES_RESP:
      return {
        ...state,
        citiesdata: action.data,
      };
    case GET_QUIZ_RESP:
      return {
        ...state,
        quizdata: action.data,
      };
    case GET_QUIZ_STATUS_RESP:
      return {
        ...state,
        quizstatusdata: action.data,
      };
    case GET_UPDATE_QUIZ_RESP:
      return {
        ...state,
        updatequizstatusdata: action.data,
      };
    case SKIP_QUESTIONS_RESP:
      return {
        ...state,
        skip_questions_data: action.data,
      };
    case GET_LETTER_RESP:
      return {
        ...state,
        letterdata: action.data,
      };
    case CREATE_LETTER_RESP:
      return {
        ...state,
        data: action.data,
      };
    case GET_TEMPLATE_RESP:
      return {
        ...state,
        templatedata: action.data,
      };

    case CREATE_TEMPLATE_RESP:
      return {
        ...state,
        data: action.data,
      };

    case Get_Question_Resp:
      return {
        ...state,
        data: action.data,
      };

    case GET_QUEST_ATTR_RESP:
      return {
        ...state,
        data: action.data,
      };

    case GET_FILTER_QUES_RESP:
      return {
        ...state,
        data: action.data,
      };

    case GET_LETTER_INTENT_RESP:
      return {
        ...state,
        letterintentdata: action.data,
      };

    case GET_SINGLE_TEMPLATE_RESP:
      return {
        ...state,
        single_template_data: action.data,
      };

    case EDIT_JOB_RESP:
      return {
        ...state,
        edit_job_data: action.data,
      };

    case UPDATE_JOB_RESP:
      return {
        ...state,
        update_job_data: action.data,
      };

    case JOB_ADD_RESP:
      return {
        ...state,
        add_job_data_rec: action.data,
      };

    case SELECTED_SKILLS_DATA:
      return {
        ...state,
        selectedSKills: action.data,
      };

    case GET_ALL_JOBS_RESP:
      return {
        ...state,
        jobsData: action.data,
      };

    case APPLY_JOB_RESP:
      return {
        ...state,
        data: action.data,
      };

    case EDIT_QUIZ_RESP:
      return {
        ...state,
        data: action.data,
      };
    case JOB_DETAIL_RESP:
      return {
        ...state,
        job_detail: action.data,
      };

    case GET_LOCATION_RESP:
      return {
        ...state,
        location_res: action.data,
      };

    case GET_SKILLS_FILTER_RESP:
      return {
        ...state,
        skills_filter: action.data,
      };

    case GET_CITIES_FILTER_RESP:
      return {
        ...state,
        cities_filter: action.data,
      };

    case SEARCH_DATA:
      return {
        ...state,
        search_data: action.data,
      };

    case GET_CAND_PROFILE_CARDS_RESP:
      return {
        ...state,
        cand_cards: action.data,
      };

    case GET_DESIGNATION_RESP:
      return {
        ...state,
        designations_data: action.data,
      };

    case EDIT_PROFILE_RESP:
      return {
        ...state,
        edit_profile_data: action.data,
      };

    case ATTEMPT_QUIZ_RESP:
      return {
        ...state,
        attempt_quiz_res: action.data,
      };

    case SHOW_QUIZ_ANS_RESP:
      return {
        ...state,
        show_quiz_ans_res: action.data,
      };

    case SELECT_ANSWER_RESP:
      return {
        ...state,
        select_answer_res: action.data,
      };

    case SEND_ANSWER_RESP:
      return {
        ...state,
        send_answer_res: action.data,
      };

    case START_QUIZ_RESP:
      return {
        ...state,
        start_quiz_res: action.data,
      };

    case FINAL_SUBMIT_RESP:
      return {
        ...state,
        final_submit_res: action.data,
      };

    case QUIZ_RESULT:
      return {
        ...state,
        quiz_result: action.data,
        quiz_result_status:action.submit_message,
      };

    case GET_APPLIED_JOBS_RESP:
      return {
        ...state,
        applied_jobs_res: action.data,
      };

    case GET_COUNT_JOBS_RESP:
      return {
        ...state,
        get_count_jobs_resp: action.data,
      };

    case LIKE_SAVE_JOB_RESP:
      return {
        ...state,
        like_save_job_res: action.data,
      };

    case JOB_STATUS_RESP:
      return {
        ...state,
        job_status_res: action.data,
      };

    case CHANGE_PASS_RESP:
      return {
        ...state,
        change_password_res: action.data,
      };

    case SET_PASS_RESP:
      return {
        ...state,
        set_password_res: action.data,
      };

    case JOB_TYPES_RESP:
      return {
        ...state,
        job_type_res: action.data,
      };

    case JOB_TYPE_DETAILS_RESP:
      return {
        ...state,
        job_type_details_res: action.data,
      };

    case JOB_DESC_RESP:
      return {
        ...state,
        job_desc_res: action.data,
      };

    case GET_COMPANY_CATEGORY_RESP:
      return {
        ...state,
        comp_category_res: action.data,
      };

    case GET_COMPANY_SUB_CATEGORY_RESP:
      return {
        ...state,
        comp_sub_category_res: action.data,
      };

    case GET_STREAMS_RES:
      return {
        ...state,
        get_streams_res: action.data,
      };

    case GET_COUNTRY_RESP:
      return {
        ...state,
        country_res: action.data,
      };

    case GET_STATE_RESP:
      return {
        ...state,
        state_res: action.data,
      };

    case GET_CITY_RESP:
      return {
        ...state,
        city_res: action.data,
      };

    case GET_PROBABLE_CANDIDATES_RESP:
      return {
        ...state,
        probable_candidates_res: action.data,
      };

    case GET_JOB_CATEGORIES_RESP:
      return {
        ...state,
        get_job_cat_res: action.data,
      };

    case GET_ALL_MEMBERS_RESP:
      return {
        ...state,
        get_all_members_res: action.data,
      };

    case GET_OFFER_LETTERS_RESP:
      return {
        ...state,
        get_offer_letters_res: action.data,
      };

    case CHANGE_JOB_STATUS_RESP:
      return {
        ...state,
        change_job_status_res: action.data,
      };

    case SCHEDULE_INTERVIEW_RESP:
      return {
        ...state,
        schedule_interview_res: action.data,
      };

    case GET_CURRENT_PLAN_FEATURES_RESP:
      return {
        ...state,
        curr_plan_feature_res: action.data,
      };
    case GET_COUNTRY_CATEGORY_RESP:
      return {
        ...state,
        country_category_res: action.data,
      };
    case GET_STATE_CATEGORY_RESP:
      return {
        ...state,
        state_category_res: action.data,
      };

    case DELETE_USER_CONFIRMATION:
      return {
        ...state,
        delete_user: action.data,
      };
    case GET_COUNTRY_CATEGORY_P_RESP:
      return {
        ...state,
        country_category_p_res: action.data,
      };
    case GET_STATE_CATEGORY_P_RESP:
      return {
        ...state,
        state_category_p_res: action.data,
      };
    case GET_CITY_CATEGORY_RESP:
      return {
        ...state,
        city_category_res: action.data,
      };
    case GET_CITY_CATEGORY_P_RESP:
      return {
        ...state,
        city_category_p_res: action.data,
      };
    case SITE_DATA:
      return {
        ...state,
        site_data: action.data,
      };
    case GET_EDUCATION_LEVELS_RESP:
      return {
        ...state,
        get_education_levels_resp: action.data,
      };
    case GET_COURSES_RESP:
      return {
        ...state,
        get_courses_resp: action.data,
      };
    case GET_SPECIALIZATIONS_RESP:
      return {
        ...state,
        get_specializations_resp: action.data,
      };
    case GET_GRADES_RESP:
      return {
        ...state,
        get_grades_resp: action.data,
      };
    case GET_COURSE_DURATION_RESP:
      return {
        ...state,
        get_course_duration_resp: action.data,
      };
    case GET_YEARS_RESP:
      return {
        ...state,
        get_years_resp: action.data,
      };
    case UPLOAD_PROFILE_IMAGE_RESP:
      return {
        ...state,
        upload_profile_image_resp: action.data,
      };
    case GET_COMPANY_DETAIL_RESP:
      return {
        ...state,
        get_company_detail_resp: action.data,
      };
    case GET_CAMPUS_DETAIL_RESP:
      return {
        ...state,
        get_campus_detail_resp: action.data,
      };
    case GET_LIST_ALL_JOB_RESP:
      return {
        ...state,
        get_list_all_job_resp: action.data,
      };
    case GET_SALARIES_RESP:
      return {
        ...state,
        get_salaries_resp: action.data,
      };
    case GET_WORK_EXPERIENCES_RESP:
      return {
        ...state,
        get_work_experiences_resp: action.data,
      };
    case GET_NEW_PLAN_RESP:
      return {
        ...state,
        get_new_plan_resp: action.data,
      };
    case FEATURE_PLAN_RESP:
      return {
        ...state,
        feature_plan_resp: action.data,
      };
    case GET_ALL_JOBS_DASHBOARD_RES:
      return {
        ...state,
        get_all_jobs_dashboard_resp: action.data,
      };
    case GET_CANDIDATE_TYPE_RES:
      return {
        ...state,
        get_candidate_type_resp: action.data,
      };
    case GET_QUIZZES_DASHBOARD_RESP:
      return {
        ...state,
        get_quizzes_dashboard_resp: action.data,
      };
    case MY_JOBDESCRIPTION_RESP:
      return {
        ...state,
        my_jobdescription_resp: action.data,
      };
    case GET_JOBDESCDETAILS_RESP:
      return {
        ...state,
        get_jobdescdetails_resp: action.data,
      };
    case CREATE_QUIZ_NEW_RESP:
      return {
        ...state,
        quizdata_resp: action.data,
      };
    case ADD_EDIT_JOB_RESP:
      return {
        ...state,
        add_edit_job_resp: action.data,
      };
    case JOB_STATUS_UPDATE_RESP:
      return {
        ...state,
        job_status_update_resp: action.data,
      };
    case GET_DRAFT_JOBS_RESP:
      return {
        ...state,
        get_draft_jobs_resp: action.data,
      };
    case ADD_EDIT_JOB_MAPPING_RESP:
      return {
        ...state,
        add_edit_job_mapping_resp: action.data,
      };
    case REGISTER_SUCCESS_RESP:
      return {
        ...state,
        register_success_resp: action.data,
      };
    case GET_QUIZ_DETAIL_RESP:
      return {
        ...state,
        get_quiz_detail_resp: action.data,
      };
    case GET_ALL_TAGS_RESP:
      return {
        ...state,
        get_all_tags_resp: action.data,
      };
    case GET_TAGS_BY_TYPE_RESP:
      return {
        ...state,
        get_tags_by_type_resp: action.data,
      };
    case GET_JOBDETAILSWITHMAP_RESP:
      return {
        ...state,
        get_jobdetailswithmap_resp: action.data,
      };
    case APPLIED_QUIZ_DETAILS_RESP:
      return {
        ...state,
        applied_quiz_details_resp: action.data,
      };
    case GET_JOBDETAIL_RESP:
      return {
        ...state,
        get_jobDetail_resp: action.data,
      };
    case GET_CURRENT_USER_JOB_TYPES_RESP:
      return {
        ...state,
        get_current_user_job_types_resp: action.data,
      };
    case GET_INDUSTRIES_RESP:
      return {
        ...state,
        get_industries_resp: action.data,
      };
    case GET_INVOICE_RESP:
      return {
        ...state,
        get_invoice_resp: action.data,
      };
    case PROBABLE_CANDIDATENOTI_RESP:
      return {
        ...state,
        probable_candidatenoti_res: action.data,
      };
    case GET_ACCOUNT_RESP:
      return {
        ...state,
        get_account_resp: action.data,
      };
    case GET_COUNTRY_CALLING_CODES_RESP:
      return {
        ...state,
        get_country_calling_codes_resp: action.data,
      };


   case GET_STD_CALLING_CODES_RESP:
        return {
          ...state,
          get_std_calling_codes_resp: action.data,
        };
    case GET_SMS_TEMPLATES_RESP:
      return {
        ...state,
        get_sms_templates_resp: action.data,
      };
    case GET_TEMPLATE_LIST_RESP:
      return {
        ...state,
        get_template_list_resp: action.data,
      };
    case VALIDATE_TOTAL_QUESTION_RESP:
      return {
        ...state,
        validate_total_question_resp: action.data,
      };

    default:
      return state;
  }
}
